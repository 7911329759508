import React from "react"
import Css from "./ProcessCardsSection.module.css"


const ProcessCardsSection = props => {

  let data = props.content["cards-section"]
  let bgColor = (props.bgColor)?props.bgColor:"#FFFFFF"

  let cards = []

  if (data) {
    cards = data.map( (card, idx) => {

        let items = card.items.map( (item, idx) => {

            return <li key={idx} className={Css.Text}>{item}</li>
        })

        return <div key={idx} className={Css.CardsContainer}>
        <div className={Css.LeftPanel}>
            <div className={Css.Index}>{card['index']}</div>
            <div>          
                <img className={Css.Picture} src={require('../../images/' + card['image'])} alt="Card 1" />
           </div>
        </div>
        <div className={Css.RightPanel}>
            <div className={Css.CardTitle}>{card['title']}</div>
            <div className={Css.CardDescription}>{card['description']}</div>
            <div className={Css.CardItemsTitle}>{card['items-title']}</div>
            <ul className={Css.CardItems}>{items}</ul>
            </div>
        </div>
    }) 
  }

  return (
    <div className={Css.ContentHeader} style={{backgroundColor:bgColor}} >{cards}</div>
  )
}

export default ProcessCardsSection